import React from 'react';
import dynamic from 'next/dynamic';
import { getLog } from '@aurora/shared-utils/log';
import type { ArchivedMessageViewFragment } from '../../../types/graphql-types';
import type { ItemType } from '../../../types/enums';
import { ArchivedMessageViewVariant } from '../../../types/enums';
import type { ItemViewFC, ItemViewVariantFC } from '../../entities/types';

// eslint-disable-next-line import/no-cycle
const ArchivedMessageViewTableRow = dynamic(
  () => import('./ArchivedMessageViewTableRow/ArchivedMessageViewTableRow')
);
const ArchivedMessageViewInline = dynamic(
  () => import('./ArchivedMessageViewInline/ArchivedMessageViewInline')
);

const log = getLog(module);

const variantToComponentMap: Record<
  ArchivedMessageViewVariant,
  ItemViewVariantFC<
    ArchivedMessageViewFragment,
    ItemType.ARCHIVED_MESSAGE,
    ArchivedMessageViewVariant
  >
> = {
  inline: ArchivedMessageViewInline,
  tableRow: ArchivedMessageViewTableRow
};

/**
 * The archived message view component that renders the message view specified by
 * the <code>variant</code> parameter. This component should be used anywhere an
 * archived message is rendered.
 *
 * @author Akash Goenka
 */
const ArchivedMessageView: ItemViewFC<
  ArchivedMessageViewFragment,
  ItemType.ARCHIVED_MESSAGE,
  ArchivedMessageViewVariant
> = ({
  entity: archivedMessage,
  variant = { type: ArchivedMessageViewVariant.TABLE_ROW },
  className
}) => {
  const ArchivedMessageViewComponent = variantToComponentMap[variant?.type];
  if (ArchivedMessageViewComponent) {
    return (
      <ArchivedMessageViewComponent
        entity={archivedMessage}
        variant={variant}
        className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...variant.props}
      />
    );
  }
  log.warn('No registered archived message view for variant type [%s]', variant.type);
  return null;
};

export default ArchivedMessageView;
